module.exports={
  "//": "This is an automatic translation. Help us to improve it.",
  "loadingTitle":                  "請稍候...",
  "close":                         "關閉",
  "windowsAuthTitle":              "Windows 驗證",
  "invalid":                       "不合法的",
  "mustMatch":                     "必需符合",
  "loginSocialButton":             "{connection:title} 登入",
  "signupSocialButton":            "{connection:title} 註冊",
  "networkError":                  "無法連線至伺服器<br/>請稍後再試",
  "noConnectionError":             "没有有效的连接，可以找到。",
  "signin": {
    "title":                       "登入",
    "action":                      "登入",
    "actionDomain":                "登入 {domain}",
    "all":                         "顯示全部",
    "strategyEmailEmpty":          "請輸入電子郵件地址。",
    "strategyEmailInvalid":        "無效的電子郵件地址。",
    "strategyDomainInvalid":       "網域 {domain} 是不合法的。",
    "signinText":                  "登入",
    "signupText":                  "註冊",
    "forgotText":                  "忘記密碼？",
    "cancelAction":                "取消",
    "footerText":                  "",
    "emailPlaceholder":            "電子郵件",
    "usernamePlaceholder":         "帳號",
    "passwordPlaceholder":         "密碼",
    "separatorText":               "或",
    "serverErrorText":             "登入系統發生錯誤，請聯絡系統管理員。",
    "returnUserLabel":             "您使用的最後一次登入...",
    "domainUserLabel":             "連線來自於您的組織網路",
    "wrongEmailPasswordErrorText": "錯誤的電子郵件或密碼。",
    "or":                          "...或註冊",
    "loadingMessage":              "登入中 {connection}...",
    "popupCredentials":            "請在彈出視窗中輸入您的憑證。",
    "userClosedPopup":             "彈出視窗被關閉，請再試一次。",
    "userConsentFailed":           "權限不足，請再試一次。"
  },
  "signup": {
    "description":                 "",
    "title":                       "註冊",
    "action":                      "註冊",
    "signinText":                  "登入",
    "signupText":                  "註冊",
    "emailPlaceholder":            "電子郵件",
    "usernamePlaceholder":         "帳號",
    "passwordPlaceholder":         "密碼",
    "cancelAction":                "取消",
    "headerText":                  "請輸入您的電子郵件與密碼",
    "footerText":                  "",
    "serverErrorText":             "註冊系統發生錯誤，請聯絡系統管理員。",
    "userExistsErrorText":         "帳號已存在。",
    "signupOnSSODomainErrorText":  "網域 {domain} 已被設置為 Single Sign On 故無法註冊帳號，請嘗試登入",
    "usernameInUseErrorText":      "帳號已存在。",
    "invalidPassword":             "密碼強度不足。",

    "passwordStrength": {
      "nonEmpty": "請輸入密碼。",
      "lengthAtLeast": "密碼長度需要至少 %d 個字",
      "shouldContain": "必須包含 ",
      "containsAtLeast" : "密碼長度需要至少 %d 個字並且包括 %d 種特殊字元 ",
      "lowerCase": "英文小寫 (a-z)",
      "upperCase": "英文大寫 (A-Z)",
      "numbers": "數字 (0-9)",
      "specialCharacters" : "特殊字元 (!@#$%^&*)",
      "identicalChars": "不允許連續相同的 %d 個字 (e.g., \"%s\")"
    }

  },
  "newReset": {
    "title":                       "忘記密碼",
    "action":                      "發送",
    "emailPlaceholder":            "電子郵件",
    "cancelAction":                "取消",
    "footerText":                  "",
    "successText":                 "我們已寄送重置密碼的確認信至您的電子郵件信箱，請前往收取。",
    "serverErrorText":             "重置密碼系統發生錯誤，請聯絡系統管理員。",
    "headerText":                  "请输入您的电子邮件地址。我们会向您发送电子邮件重设密码。",
    "userDoesNotExistErrorText":   "帳號不存在。",
    "tooManyRequestsErrorText":    "您已达密码重置尝试的极限。重试前请稍候。"
  },
  "reset": {
    "title":                       "忘記密碼",
    "action":                      "發送",
    "emailPlaceholder":            "電子郵件",
    "passwordPlaceholder":         "新密碼",
    "repeatPasswordPlaceholder":   "確認新密碼",
    "cancelAction":                "取消",
    "footerText":                  "",
    "successText":                 "我們已寄送重置密碼的確認信至您的電子郵件信箱，請前往收取。",
    "enterSamePasswordText":       "請輸入相同的密碼。",
    "headerText":                  "請輸入您的電子郵件與新的密碼，我們將向您發送電子郵件以確認密碼更改。",
    "serverErrorText":             "重置密碼系統發生錯誤，請聯絡系統管理員。",
    "userDoesNotExistErrorText":   "帳號不存在。",
    "tooManyRequestsErrorText":    "您已达密码重置尝试的极限。重试前请稍候。",
    "invalidPassword":             "密碼強度不足。"
  }
}
