module.exports={
  "//": "This is an automatic translation. Help us to improve it.",
  "loadingTitle": "DubelmoHchugh loS.",
  "noConnectionError": "laH tu' valid pagh connection.",
  "close": "SoQmoH",
  "signin": {
    "title": "qI'",
    "action": "qI'",
    "all": "cha' Hoch",
    "strategyEmailEmpty": "chIm email.",
    "strategyEmailInvalid": "email invalid.",
    "strategyDomainInvalid": "{domain} yer wej setup.",
    "signinText": "qI'",
    "signupText": "qI'",
    "forgotText": "mu'wIj lIj?",
    "emailPlaceholder": "email",
    "usernamePlaceholder": "tus neeg siv lub npe",
    "passwordPlaceholder": "mu'wIj",
    "separatorText": "ghap",
    "serverErrorText": "Qagh qaStaHvIS qI' mIw tu'lu'.",
    "returnUserLabel": "Qav poH qI' SoH qaStaHvIS lo'.",
    "wrongEmailPasswordErrorText": "QIH email pagh mu'wIj.",
    "or": "... pagh qI' qaStaHvIS yIlo'",
    "loadingMessage": "Ukubhalisela In nge {connection}..."
  },
  "signup": {
    "description": "",
    "title": "qI'",
    "action": "qI'",
    "signinText": "qI'",
    "signupText": "qI'",
    "emailPlaceholder": "email",
    "passwordPlaceholder": "mu'wIj chenmoH",
    "cancelAction": "qIl",
    "headerText": "nuqneH email mu'wIj je",
    "footerText": "",
    "enterpriseEmailWarningText": "configured {domain} yer Vas qI' 'ej account pagh chenmoH SoH. nID, qI' instead.",
    "serverErrorText": "Qagh qI' mIw Ha' tu'lu'."
  },
  "newReset": {
    "title": "mu'wIj reset",
    "action": "ngeH",
    "emailPlaceholder": "email",
    "cancelAction": "qIl",
    "footerText": "",
    "successText": "jIboQqang reset mu'wIj neH ngeH SoH maH.",
    "headerText": "nuqneH email SoQ 'el. jIboQqang mu'wIj reset ngeH SoH maH.",
    "serverErrorText": "Qagh reset mu'wIj mIw tu'lu'.",
    "userDoesNotExistErrorText": "wej nIv'e' user.",
    "tooManyRequestsErrorText": "vuS mu'wIj reset nID SIch SoH. DubelmoHchugh loS pa' yInIDqa'."
  },
  "reset": {
    "title": "mu'wIj choH",
    "action": "ngeH",
    "emailPlaceholder": "email",
    "passwordPlaceholder": "mu'wIj chu'",
    "repeatPasswordPlaceholder": "mu'wIj chu' confirm",
    "cancelAction": "qIl",
    "footerText": "",
    "successText": "jIboQqang reset mu'wIj neH ngeH SoH maH.",
    "enterSamePasswordText": "DubelmoHchugh mu'wIj rap 'el.",
    "headerText": "nuqneH email chu' mu'wIj je. jIboQqang mu'wIj choH confirm ngeH SoH maH.",
    "serverErrorText": "Qagh reset mu'wIj mIw tu'lu'.",
    "userDoesNotExistErrorText": "wej nIv'e' user.",
    "tooManyRequestsErrorText": "vuS mu'wIj reset nID SIch SoH. DubelmoHchugh loS pa' yInIDqa'.",
    "invalidPassword": "mu'wIj puj je."
  }
}
