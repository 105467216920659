module.exports = (function anonymous(locals, filters, escape, rethrow
/**/) {
escape = escape || function (html){
  return String(html)
    .replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');
};
var buf = [];
with (locals || {}) { (function(){ 
 buf.push('<div class="a0-loggedin a0-mode">\n    <form novalidate>\n      <div class="a0-body-content">\n        <p class="a0-centered a0-last-time">', escape((4,  i18n.t('signin:returnUserLabel') )), '</p>\n        <div class="a0-strategy"></div>\n        <div class="a0-emailPassword a0-hide">\n            <div class="a0-email">\n                <span class="a0-email-readonly"></span>\n                <input name="email"\n                       type="email"\n                       value=""\n                       disabled\n                       placeholder="', escape((13,  i18n.t('signin:emailPlaceholder') )), '"\n                       title="', escape((14,  i18n.t('signin:emailPlaceholder') )), '"\n                       class="a0-hide">\n            </div>\n            <div class="a0-password">\n                <label class="a0-sad-placeholder">\n                  ', escape((19,  i18n.t('signin:passwordPlaceholder') )), '\n                </label>\n                <input name="password" type="password"\n                       autofocus\n                       placeholder="', escape((23,  i18n.t('signin:passwordPlaceholder') )), '"\n                       title="', escape((24,  i18n.t('signin:passwordPlaceholder') )), '">\n            </div>\n        </div>\n        <a href="#" class="a0-centered a0-all">', escape((27,  i18n.t('signin:all') )), '</a>\n      </div>\n    </form>\n</div>\n'); })();
} 
return buf.join('');
})