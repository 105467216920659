module.exports = (function anonymous(locals, filters, escape, rethrow
/**/) {
escape = escape || function (html){
  return String(html)
    .replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');
};
var buf = [];
with (locals || {}) { (function(){ 
 buf.push('<div id="a0-lock" class="a0-lock', escape((1,  (locals && locals.options.theme) ? ' a0-theme-' + locals.options.theme : '')), '" dir="auto">\n  <div class="a0-signin">\n    <div class="a0-popup ', escape((3,  (locals && locals.cordova) ? 'a0-cordova' : '' )), ' ', escape((3,  (locals && locals.ios) ? 'a0-ios' : '' )), '">\n      <div class="a0-overlay">\n        <div class="', escape((5,  locals && locals.options.container ? 'a0-nocentrix' : 'a0-centrix' )), '">\n          ' + (function(){var buf = [];
 buf.push('<div id="a0-onestep" class="a0-panel a0-onestep a0-free-subscription">\n    ' + (function(){var buf = [];
 buf.push('<div class="a0-header a0-top-header ', escape((1,  locals.embedded ? 'a0-hide' : '')), '">\n\n    <div class="a0-bg-gradient ', escape((3,  cssBlurSupport ? 'a0-blur-support' : 'a0-blur-no-support' )), '">\n      <div class="a0-avatar a0-hide">\n        <img src="">\n      </div>\n\n      <div class="a0-image">\n        <img class="a0-avatar-guest" src="', escape((9,  options.headerIcon )), '">\n      </div>\n\n      <div class="a0-bg-solid-color" style="background-color: ', escape((12,  options.primaryColor )), '"></div>\n    </div>\n\n    <div class="a0-icon-container">\n      <div class="a0-avatar a0-hide">\n        <img src="">\n      </div>\n\n      <div class="a0-image">\n        <img class="a0-avatar-guest" src="', escape((21,  options.headerIcon )), '">\n      </div>\n\n      <div class="a0-blank-space"></div>\n\n      <h1 title="', escape((26,  options.i18n.t('signin:title') )), '">', escape((26,  options.i18n.t('signin:title') )), '</h1>\n    </div>\n\n    <h2 class="a0-error a0-hide">&nbsp;</h2>\n    <h2 class="a0-success a0-hide">&nbsp;</h2>\n\n    <!-- if standalone, you shouldn\'t be able to close the widget -->\n    <a class="a0-close ', escape((33,  options.closable ? '' : 'a0-hide' )), '" href="#">\n      <svg enable-background="new 0 0 128 128" version="1.1" viewBox="0 0 128 128" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><polygon fill="#373737" points="123.5429688,11.59375 116.4765625,4.5185547 64.0019531,56.9306641 11.5595703,4.4882813     4.4882813,11.5595703 56.9272461,63.9970703 4.4570313,116.4052734 11.5244141,123.4814453 63.9985352,71.0683594     116.4423828,123.5117188 123.5126953,116.4414063 71.0732422,64.0019531   "></polygon></g></svg>\n    </a>\n</div>\n');
return buf.join('');})() + '\n\n    <div class="a0-mode-container"></div>\n\n    ' + (function(){var buf = [];
 buf.push('<div class=\'a0-footer\'>\n    <a href="http://auth0.com/?utm_source=lock&utm_campaign=badge&utm_medium=widget" target="_new" class="a0-logo">\n        <i class="a0-icon-badge"></i>\n    </a>\n</div>\n');
return buf.join('');})() + '\n</div>\n');
return buf.join('');})() + '\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n'); })();
} 
return buf.join('');
})