module.exports={
  "loadingTitle":                  "Vent venligst...",
  "close":                         "Luk",
  "windowsAuthTitle":              "Windows Authentication",
  "invalid":                       "Ugyldig",
  "mustMatch":                     "Skal stemme overens",
  "loginSocialButton":             "Login med {connection:title}",
  "signupSocialButton":            "Opret med {connection:title}",
  "networkError":                  "Vi kunne ikke få kontakt til serveren.<br/>Prøv venligst igen.",
  "noConnectionError":             "Ingen gyldig forbindelse kunne findes.",
  "signin": {
    "title":                       "Log ind",
    "action":                      "Log ind",
    "actionDomain":                "Log ind på {domain}",
    "all":                         "Ikke din konto?",
    "strategyEmailEmpty":          "Email er tom.",
    "strategyEmailInvalid":        "Email is ugyldig.",
    "strategyDomainInvalid":       "Domænet {domain} er ikke sat op.",
    "signinText":                  "Log ind",
    "signupText":                  "Opret",
    "forgotText":                  "Glemt kodeord",
    "cancelAction":                "Annuller",
    "emailPlaceholder":            "Email",
    "usernamePlaceholder":         "Brugernavn",
    "passwordPlaceholder":         "Kodeord",
    "separatorText":               "eller",
    "serverErrorText":             "Der opstod en fejl under login.",
    "returnUserLabel":             "Ved sidste log ind benyttede du...",
    "domainUserLabel":             "Du er forbundet fra din virksomheds netværk...",
    "wrongEmailPasswordErrorText": "Forkert email eller password.",
    "or":                          "... eller log ind med",
    "loadingMessage":              "Logger ind med {connection}...",
    "popupCredentials":            "Indtast dit login i pop-up vinduet",
    "userClosedPopup":             "Popup vinduet lukkede. Prøv igen.",
    "userConsentFailed":           "Tilladelse blev ikke givet. Prøv igen."
  },
  "signup": {
    "description":                 "",
    "title":                       "Opret",
    "action":                      "Opret",
    "signinText":                  "Log ind",
    "signupText":                  "Opret",
    "emailPlaceholder":            "Email",
    "usernamePlaceholder":         "Brugernavn",
    "passwordPlaceholder":         "Opret et kodeord",
    "cancelAction":                "Annuller",
    "headerText":                  "Indtast din email og dit kodeord",
    "footerText":                  "",
    "signupOnSSODomainErrorText":  "Dette domæne {domain} er blevet konfigureret til Single Sign On og du kan ikke oprette en konto. Prøv at logge ind i stedet.",
    "serverErrorText":             "Der opstod en fejl under oprettelse.",
    "userExistsErrorText":         "Denne bruger eksisterer allerede.",
    "usernameInUseErrorText":      "Brugernavnet er allerede i brug.",
    "invalidPassword":             "Kodeordet er for svagt.",

    "passwordStrength": {
      "nonEmpty": "Et ikke-tomt kodeord kræves",
      "lengthAtLeast": "Mindst %d tegn langt",
      "shouldContain": "Skal indeholde:",
      "containsAtLeast" : "Skal indeholde mindst %d af følgende %d typer tegn:",
      "lowerCase": "Små bogstaver (a-z)",
      "upperCase": "Store bogstaver (A-Z)",
      "numbers": "Numre (0-9)",
      "specialCharacters" : "Specialtegn (f.eks. !@#$%^&*)",
      "identicalChars": "Ikke mere end %d identiske tegn i træk (f.eks. er \"%s\" ikke tilladt)"
    }

  },
  "newReset": {
    "title":                       "Nulstil Kodeord",
    "action":                      "Send",
    "emailPlaceholder":            "Email",
    "cancelAction":                "Annuller",
    "footerText":                  "",
    "successText":                 "Vi har lige sendt dig en email for at nulstille dit kodeord.",
    "headerText":                  "Indtast din e-mailadresse. Vi sender dig en e-mail for at nulstille din adgangskode.",
    "serverErrorText":             "Der opstod en fejl under behandling af nulstilling af kodeord.",
    "userDoesNotExistErrorText":   "Brugeren findes ikke.",
    "tooManyRequestsErrorText":    "Du har nået grænsen for nulstilling af adgangskoden forsøg. Vent venligst før du prøver igen."
  },
  "reset": {
    "title":                       "Ændre Kodeord",
    "action":                      "Send",
    "emailPlaceholder":            "Email",
    "passwordPlaceholder":         "Nyt Kodeord",
    "repeatPasswordPlaceholder":   "Bekræft Nyt Kodeord",
    "cancelAction":                "Annuller",
    "footerText":                  "",
    "successText":                 "Vi har lige sendt dig en email for at nulstille dit kodeord.",
    "enterSamePasswordText":       "Indtast det samme kodeord.",
    "headerText":                  "Indtast venligst din email og dit nye kodeord. Vi sender dig en email for at bekræfte ændringen.",
    "serverErrorText":             "Der opstod en fejl under behandling af nulstilling af kodeord.",
    "userDoesNotExistErrorText":   "Brugeren findes ikke.",
    "tooManyRequestsErrorText":    "Du har nået grænsen for nulstilling af adgangskoden forsøg. Vent venligst før du prøver igen.",
    "invalidPassword":             "Kodeordet er for svagt."
  }
}
